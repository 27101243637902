<template>
  <div>
    <risk-place-form
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      :data-e-i="dataEI"
      @refetch-data="refetchData"
      @error-data="showError"
    />
    <b-row>
      <b-col
        cols="12"
        md="4"
        class="pr-0"
      >
        <b-card
          no-body
          style="height: 100vh !important;"
        >
          <b-card-header class="flex-column align-items-start">
            <b-card-title>Instituciones Educativas</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-card-text class="pt-0">
              <div
                v-for="(vehicle, index) in items"
                :key="`list-ie-${index}`"
                class="mb-6 pb-2"
              >
                <div
                  class="d-flex align-center justify-space-between cursor-pointer"
                  @click="flyToLocation(geojson.features[index].geometry.coordinates, index)"
                >
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        rounded="sm"
                        :variant="`light-${resolveColorByIndex(index)}`"
                      >
                        <feather-icon icon="HomeIcon" />
                      </b-avatar>
                    </template>
                    <span
                      class="font-weight-bold d-block text-nowrap"
                      style="line-height: 1rem; color: #000000b3"
                    >
                      {{ vehicle.name }}
                    </span>
                    <b-badge
                      size="sm"
                      :variant="`light-${resolveColorByIndex(index)}`"
                      style="padding-top: 0.1rem; padding-bottom: 0.1rem; padding-left: 0.15rem; padding-right: 0.15rem; font-size: 0.8rem;"
                    >
                      Lugares de Riesgo
                      <b-badge
                        variant="light"
                        style="padding: 0.15rem; border-radius: 0.158rem; top: 0px; color: darkslategray;"
                      >
                        {{ vehicle.dataEI.riskPlaces.length }}
                      </b-badge>
                    </b-badge>
                  </b-media>
                </div>
                <div>
                  <div v-show="vehicle.showPanelito">
                    <div class="mt-1">
                      <b-table
                        responsive="sm"
                        :items="vehicle.dataEI.riskPlaces"
                        :fields="fieldsTable"
                      >
                        <template #head(acciones)>
                          <b-button
                            v-b-tooltip.hover.right="`Agregar Lugar de Riesgo`"
                            variant="primary"
                            size="sm"
                            style="padding: 0.3rem 0.5rem;"
                            @click="addRiskPlace('new', vehicle.dataEI)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                            />
                          </b-button>
                        </template>

                        <template #cell(name)="data">
                          <div class="d-flex align-items-center">
                            <div>
                              <div class="font-small-2 font-weight-bolder">
                                {{ data.item.name }}
                              </div>
                              <div class="font-small-1 text-muted">
                                {{ data.item.typeRiskPlace.name }}
                              </div>
                            </div>
                          </div>
                        </template>

                        <template #cell(acciones)="rp">
                          <b-button
                            v-b-tooltip.hover.top="`Ver en Mapa`"
                            variant="light-dark"
                            class="btn-icon"
                            size="sm"
                            @click="flyToLocationRP(rp.item.idRiskPlace)"
                          >
                            <feather-icon icon="MapPinIcon" />
                          </b-button>
                          <b-button
                            v-b-tooltip.hover.top="`Editar`"
                            variant="light-dark"
                            class="btn-icon"
                            size="sm"
                            @click="getEditRiskPlace(row.item, rp.item)"
                          >
                            <feather-icon icon="EditIcon" />
                          </b-button>
                          <b-button
                            v-b-tooltip.hover.top="`Eliminar`"
                            variant="light-dark"
                            class="btn-icon"
                            size="sm"
                            @click="getDeleteRiskPlace(rp.item)"
                          >
                            <feather-icon icon="TrashIcon" />
                          </b-button>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
        class="pl-0"
      >
        <div
          id="mapContainer"
          ref="mapContainer"
          class="basemap"
        />
        <b-modal
          ref="infoModal"
          title="Información del Marcador"
        >
          <p>{{ markerInfo.name }}</p>
          <p>{{ markerInfo.location }}</p>
          <!-- Agrega más detalles según tus necesidades -->
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { BModal, BMedia, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BAvatar, BButton, BBadge, BTable, VBTooltip } from 'bootstrap-vue'
import store from '@/store'
import mapboxgl from 'mapbox-gl'
import { resolveColorByIndex } from '@/helpers/customs'
import RiskPlaceForm from './RiskPlaceForm.vue'

export default {
  components: {
    BModal,
    BMedia,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BAvatar,
    BButton,
    BBadge,
    BTable,
    RiskPlaceForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      resolveColorByIndex,
    }
  },
  setup() {
    const schoolPin = require('@/assets/images/misc/pin-school.png')
    const riskPlacePin = require('@/assets/images/misc/pin-riskplace.png')
    const isLeftSidebarOpen = ref(true)
    const refCars = ref([])
    const isBusy = ref(false)
    const items = ref([])
    const locations = ref([])
    const locationsRP = ref([])
    const totalItems = ref(0)
    const isToggleSidebar = ref(false)
    const formType = ref('')
    const dataEdit = ref({})
    const dataEI = ref({})
    const markerInfo = ref({ name: '', location: '' })
    const infoModal = ref(null)

    const fieldsTable = ref([
      { key: 'idRiskPlace', label: 'Id', thClass: ['d-none', 'celStech'], tdClass: ['d-none', 'celStech'] },
      { key: 'name', label: 'Lugar de Riesgo', thClass: ['celStech'], tdClass: ['celStech'] },
      { key: 'acciones', thClass: ['text-center', 'wlae-30', 'celStech'], tdClass: ['text-center', 'wlae-30', 'celStech'] },
    ])

    const accessToken = 'pk.eyJ1Ijoic29jaWFsZXhwbG9yZXIiLCJhIjoiREFQbXBISSJ9.dwFTwfSaWsHvktHrRtpydQ'
    const map = ref(null)
    const mapContainer = ref(null)
    const geojson = {
      type: 'FeatureCollection',
      features: [],
    }

    const loadRecords = async () => {
      isBusy.value = true
      await store
        .dispatch('ppptcd/EDUCATIONAL_INSTITUTION_FIND_ALL', {
          limit: 500,
          query: '',
          page: 1,
          sortBy: '',
          type: 'grilla',
        })
        .then(response => {
          totalItems.value = response.totalItems
          // console.log(response.items)
          response.items.forEach((ei, ix) => {
            locations.value.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [ei.longitude, ei.latitude],
              },
            })

            ei.riskPlaces.forEach(rp => {
              locationsRP.value.push({
                idRP: rp.idRiskPlace,
                data: rp,
                type: 'RiskPlace',
                geometry: {
                  type: 'Point',
                  coordinates: [rp.longitude, rp.latitude],
                },
              })
            })

            items.value.push({
              name: ei.name,
              location: ei.address,
              progress: 88,
              idEducationalInstitution: ei.idEducationalInstitution,
              dataEI: ei,
              showPanelito: ix === 0,
            })
          })
        })
        .catch(error => {
          console.log(error)
          // this.showToast('Ha ocurrido un error', error, 'danger')
        })
      geojson.features = locations.value
      geojson.featuresRP = locationsRP.value
      // console.log('features', geojson.features)
      // console.log('featuresRP', geojson.featuresRP)
      isBusy.value = false
    }

    const activeIndex = ref(0)

    const addRiskPlace = (type, data) => {
      dataEI.value = data
      formType.value = type
      isToggleSidebar.value = true
    }

    const showError = () => {
      console.log('showError funcition')
    }

    const flyToLocation = (geolocation, index) => {
      activeIndex.value = index

      items.value = items.value.map((ele, ix) => ({
        ...ele,
        showPanelito: ix === index,
      }))

      map.value.flyTo({
        center: geolocation,
        zoom: 16,
      })
    }

    const flyToLocationRP = id => {
      const geolocation = geojson.featuresRP.find(feat => feat.idRP === id)
      // geojson.featuresRP[0].geometry.coordinates
      map.value.flyTo({
        center: geolocation.geometry.coordinates,
        zoom: 16,
      })
    }

    const openInfoModal = index => {
      markerInfo.value = {
        name: items.value[index].name,
        location: items.value[index].location,
      }
      // infoModal.value.show()
    }

    const addMarker = (type, index) => {
      if (type === 'Feature') {
        const markerElement = document.createElement('div')
        markerElement.className = 'custom-marker'
        markerElement.style.backgroundImage = `url(${schoolPin})`
        markerElement.style.width = '30px'
        markerElement.style.height = '30px'

        new mapboxgl.Marker({
          element: markerElement,
        })
          .setLngLat(geojson.features[index].geometry.coordinates)
          .addTo(map.value)
          .setPopup(new mapboxgl.Popup().setHTML(`<p>${items.value[index].name}</p>`))
          .getElement()
          .addEventListener('click', () => openInfoModal(index))
      }
      if (type === 'RiskPlace') {
        const markerElement = document.createElement('div')
        markerElement.className = 'custom-marker'
        markerElement.style.backgroundImage = `url(${riskPlacePin})`
        markerElement.style.width = '30px'
        markerElement.style.height = '30px'

        new mapboxgl.Marker({
          element: markerElement,
        })
          .setLngLat(geojson.featuresRP[index].geometry.coordinates)
          .addTo(map.value)
          .setPopup(new mapboxgl.Popup().setHTML(`
            <p style='font-weight: 500;'>${geojson.featuresRP[index].data.name}</p>
            <p style='text-align: justify;'>${geojson.featuresRP[index].data.description}</p>
            <p style='text-align: justify;'>${geojson.featuresRP[index].data.address}</p>
          `))
          .getElement()
          .addEventListener('click', () => openInfoModal(index))
      }
    }

    const initMap = async () => {
      mapboxgl.accessToken = accessToken
      map.value = new mapboxgl.Map({
        container: mapContainer.value,
        style: 'mapbox://styles/mapbox/light-v9',
        center: [-76.6312209, -7.1013809],
        zoom: 7,
      })

      await loadRecords()

      for (let index = 0; index < geojson.features.length; index += 1) {
        addMarker('Feature', index)
      }
      for (let index = 0; index < geojson.featuresRP.length; index += 1) {
        addMarker('RiskPlace', index)
      }
    }

    onMounted(async () => {
      await initMap()
      // refCars.value[activeIndex.value].classList.add('marker-focus')
    })

    const refetchData = async () => {
      await initMap()
    }

    watch(activeIndex, () => {
      refCars.value.forEach((car, index) => {
        if (index === activeIndex.value) {
          car.classList.add('marker-focus')
        } else {
          car.classList.remove('marker-focus')
        }
      })
    })

    return {
      flyToLocation,
      flyToLocationRP,
      isLeftSidebarOpen,
      accessToken,
      map,
      mapContainer,
      items,
      locations,
      geojson,
      fieldsTable,
      addRiskPlace,
      refetchData,
      showError,
      isToggleSidebar,
      formType,
      dataEdit,
      dataEI,
      openInfoModal,
      infoModal,
      markerInfo,
    }
  },
}
</script>

<style lang="scss">

.navigation-toggle-btn{
  position: absolute;
  z-index: 1;
  inset-block-start: 1rem;
  inset-inline-start: 1rem;
}

.navigation-close-btn{
  position: absolute;
  z-index: 1;
  inset-block-start: 1rem;
  inset-inline-end: 1rem;
}

.marker-focus {
  filter: drop-shadow(0 0 7px rgb(var(--v-theme-primary)));
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none;
}

.fleet-navigation-drawer{
  .v-timeline .v-timeline-divider__dot .v-timeline-divider__inner-dot{
    box-shadow: none;
  }
}

#mapContainer {
  height: 100vh !important;
}
.custom-marker {
  background-size: cover;
  cursor: pointer;
}

.wlae-30 {
  width: 120px !important;
}
.celStech {
  padding: 0.12rem 1rem !important;
}
</style>
