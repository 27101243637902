<template>
  <b-sidebar
    id="add-edit-riskplace-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >

        <div>
          <b-form-group
            label="Institución Educativa"
            label-for="nameEI"
          >
            <b-form-input
              id="nameEI"
              v-model="dataEI.name"
              type="text"
              readonly
            />
          </b-form-group>

          <h6
            class="text-info"
          >
            <feather-icon icon="AlertCircleIcon" />
            <span class="align-middle ml-25">Datos Generales</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

          <b-form-group
            label="Tipo"
            label-for="typeRiskPlace"
          >
            <v-select
              id="typeRiskPlace"
              v-model="typeRiskPlaceSel"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typesRiskPlace"
            />
          </b-form-group>

          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              type="text"
            />
          </b-form-group>

          <b-form-group
            label="Descripción"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="formData.description"
              type="text"
            />
          </b-form-group>

          <h6
            class="text-info"
          >
            <feather-icon icon="MapPinIcon" />
            <span class="align-middle ml-25">Ubicación</span>
          </h6>

          <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

          <b-form-group
            label="Seleccione Departamento"
            label-for="dpto"
          >
            <v-select
              v-model="dptoSel"
              :reduce="m => m.idUbigeo"
              label="description"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dptos"
              @input="getUbigeos('prov', dptoSel.slice(0, 2))"
            />
          </b-form-group>
          <b-form-group
            label="Seleccione Provincia"
            label-for="prov"
          >
            <v-select
              v-model="provSel"
              :reduce="m => m.idUbigeo"
              label="description"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="provs"
              @input="getUbigeos('dist', provSel.slice(0, 4))"
            />
          </b-form-group>
          <b-form-group
            label="Seleccione Distrito"
            label-for="dist"
          >
            <v-select
              v-model="distSel"
              :reduce="m => m.idUbigeo"
              label="description"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dists"
            />
          </b-form-group>

          <b-form-group
            label="Dirección"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="formData.address"
            />
          </b-form-group>

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Latitud"
                label-for="latitude"
              >
                <b-form-input
                  id="latitude"
                  v-model="formData.latitude"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Longitud"
                label-for="longitude"
              >
                <b-form-input
                  id="longitude"
                  v-model="formData.longitude"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="saveForm"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow, BCol, BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
// import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    dataEI: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const isBusy = ref(false)
    const formData = ref({})
    const typesRiskPlace = ref([])
    const typeRiskPlaceSel = ref({})
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])

    const resetForm = () => {
      formData.value = {}
    }

    const loadTypeRiskPlace = async () => {
      await store
        .dispatch('setting/TYPE_RISK_PLACE_FIND_ALL', {
          limit: 500,
          query: '',
          page: 1,
          sortBy: 'name%7CDESC',
          type: 'list',
        })
        .then(response => {
          if (response) {
            typesRiskPlace.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const saveForm = async () => {
      if (typeRiskPlaceSel.value === null || typeRiskPlaceSel.value === '') {
        emit('error-data', { response: { data: { message: 'Debe seleccionar el tipo de Lugar de Riesgo' } } })
        return
      }

      if (formData.value.name === null || formData.value.name === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar nombre de la Institución Educativa' } } })
        return
      }

      if (dptoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar departamento' } } })
        return
      }

      if (provSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar provincia' } } })
        return
      }

      if (distSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar distrito' } } })
        return
      }

      let service = 'ppptcd/RISK_PLACE_SAVE'
      if (props.formType === 'edit') {
        service = 'ppptcd/RISK_PLACE_UPDATE'
      }

      formData.value.registrationStatus = 'VERIFICADO' // REGISTRADO, VERIFICADO, DUPLICADO
      formData.value.ubigeo = distSel.value
      formData.value.typeRiskPlace = {
        idTypeRiskPlace: typeRiskPlaceSel.value.idTypeRiskPlace,
      }
      formData.value.idEducationalInstitution = props.dataEI.idEducationalInstitution

      await store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('setting/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const toggleSidebar = async val => {
      emit('update:is-toggle-sidebar', val)
      resetForm()
      loadTypeRiskPlace()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Lugar de Riesgo'
        await getUbigeos('dpto', '')
        await getUbigeos('prov', dptoSel.value.slice(0, 2))
      }
      if (props.formType === 'edit') {
        formData.value = {
          idEducationalInstitution: props.dataEdit.idEducationalInstitution,
          name: props.dataEdit.name,
          ubigeo: props.dataEdit.ubigeo,
        }
        dptoSel.value = `${props.dataEdit.ubigeo.slice(0, 2)}0000`
        await getUbigeos('dpto', '')
        await getUbigeos('prov', props.dataEdit.ubigeo.slice(0, 2))
        provSel.value = `${props.dataEdit.ubigeo.slice(0, 4)}00`
        await getUbigeos('dist', props.dataEdit.ubigeo.slice(0, 4))
        distSel.value = props.dataEdit.ubigeo
        titleForm.value = 'Editar Lugar de Riesgo'
      }
    }

    return {
      titleForm,
      isBusy,
      formData,
      saveForm,
      toggleSidebar,
      resetForm,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      typesRiskPlace,
      typeRiskPlaceSel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-edit-riskplace-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
